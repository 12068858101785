// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {rIsA03RgH: {hover: true}};

const cycleOrder = ["rIsA03RgH"];

const variantClassNames = {rIsA03RgH: "framer-v-1nuyabc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 30, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 310, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "rIsA03RgH", image: vVFo23MkD = {src: new URL("assets/1024/Spv3Nm6pVvPeqK4NXls1RjWuBA.png", import.meta.url).href, srcSet: `${new URL("assets/512/Spv3Nm6pVvPeqK4NXls1RjWuBA.png", import.meta.url).href} 512w, ${new URL("assets/1024/Spv3Nm6pVvPeqK4NXls1RjWuBA.png", import.meta.url).href} 1024w, ${new URL("assets/2048/Spv3Nm6pVvPeqK4NXls1RjWuBA.png", import.meta.url).href} 2048w, ${new URL("assets/Spv3Nm6pVvPeqK4NXls1RjWuBA.png", import.meta.url).href} 2518w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rIsA03RgH", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-8tkv4", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 1730, intrinsicWidth: 2518, pixelHeight: 1730, pixelWidth: 2518, ...toResponsiveImage(vVFo23MkD)}} className={cx("framer-1nuyabc", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rIsA03RgH"} ref={ref} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "0px 1px 0px 13px rgb(0, 0, 0)", ...style}} transition={transition} {...addPropertyOverrides({"rIsA03RgH-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-8tkv4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8tkv4 * { box-sizing: border-box; }", ".framer-8tkv4 .framer-1nld2f0 { display: block; }", ".framer-8tkv4 .framer-1nuyabc { height: 355px; overflow: hidden; position: relative; width: 516px; will-change: transform; }", ".framer-8tkv4 .framer-v-1nuyabc .framer-1nuyabc { cursor: pointer; }", ".framer-8tkv4.framer-v-1nuyabc.hover .framer-1nuyabc { aspect-ratio: 1.4535211267605634 / 1; height: var(--framer-aspect-ratio-supported, 362px); width: 526px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 355
 * @framerIntrinsicWidth 516
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"EJyltpOao":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"vVFo23MkD":"image"}
 */
const FrameryqzvNK2_w: React.ComponentType<Props> = withCSS(Component, css, "framer-8tkv4") as typeof Component;
export default FrameryqzvNK2_w;

FrameryqzvNK2_w.displayName = "data";

FrameryqzvNK2_w.defaultProps = {height: 355, width: 516};

addPropertyControls(FrameryqzvNK2_w, {vVFo23MkD: {__defaultAssetReference: "data:framer/asset-reference,Spv3Nm6pVvPeqK4NXls1RjWuBA.png?originalFilename=Capture+d%E2%80%99e%CC%81cran+2023-03-13+a%CC%80+14.52.08.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FrameryqzvNK2_w, [])